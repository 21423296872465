import React, {useEffect, useState, useRef} from 'react';
import {useSelector} from 'react-redux';
import AdminDetailListItem from './components/AdminDetailListItem';
import styles from './styles';
import {Box, Paper} from '@mui/material';
import {rightPanelData} from './item';
import {getAddressGridData} from './AddressListGridDataSource';
import TableView from 'Components/TableView';
import {sortTable} from 'Utils/helpers/SRListHelpers';
import {SortOrder} from 'Utils/enums';
import {gqlGetMemberAddress} from 'GQL/MethodsBFF/ServiceRequest';
import {v4 as uuidv4} from 'uuid';
import NoResults from 'Components/NoResults';
import Loading from 'Components/Loading';
import SnackBar from 'Components/SnackBar';

const PAGE_SIZE = 10;

const TabAdminProfile = () => {
  const member = useSelector((state: any) => state?.selectedMember?.selectedMember);
  const tenantConfig = useSelector((state: any) => state?.login?.tenantConfig);
  const loginDetails = useSelector((state: any) => state?.login);

  const [tableData, setTableData] = useState<any[]>([]);
  const [sortOrder, setSortOrder] = useState(SortOrder.DESCENDING);
  const [currentSortCriteria, setCriteriaSort] = useState('endDate');
  const [pageNo, setPageNo] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const tableRef = useRef<HTMLDivElement | null>(null);

  const fetchMemberAddress = async (page: number) => {
    if (!member?.currentMemberDetails?.userId || loading) return;

    setLoading(true);
    setError(null);

    const headers = {
      'tenant-id': loginDetails?.tenantID,
      'x-correlation-id': uuidv4(),
    };

    const payload = {
      userId: member?.currentMemberDetails?.userId,
      pageSize: PAGE_SIZE.toString(),
      pageNumber: page.toString(),
      sortOrder: '',
      sortBy: '',
    };

    try {
      const response = await gqlGetMemberAddress(payload, headers);
      const addresses = response?.data?.getMemberAddress?.previousAddressDtoApis || [];
      const isNextPagePresent =
        response?.data?.getMemberAddress?.pagingOutputDto?.isNextPagePresent;

      setTableData((prev) => (page === 0 ? addresses : [...prev, ...addresses]));
      //setHasMore(addresses.length === PAGE_SIZE);
      setHasMore(isNextPagePresent);
    } catch (err) {
      console.error('Error fetching member addresses:', err);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (member && initialLoad) {
      fetchMemberAddress(0);
      setInitialLoad(false);
    }
  }, [member]);

  const nextPage = () => {
    if (!hasMore || loading) return;
    fetchMemberAddress(pageNo + 1);
    setPageNo((prev) => prev + 1);
  };

  const onSortIconClick = (sortCriteria: string) => {
    const sortedData = sortTable(
      tableData,
      sortCriteria,
      currentSortCriteria,
      sortOrder,
      tenantConfig.locale,
    );
    setCriteriaSort(sortedData.currentSortCriteria);
    setTableData(sortedData.tableData || []);
    setSortOrder(sortedData.newSortOrder);
  };

  const handleScroll = () => {
    if (!tableRef.current) return;
    const {scrollTop, scrollHeight, clientHeight} = tableRef.current;

    // Check if the user is close to the bottom (within 20px of the end)
    if (scrollTop + clientHeight >= scrollHeight - 20 && hasMore && !loading) {
      const prevHeight = scrollHeight; // Store previous scroll height before fetching new data
      nextPage();

      // Wait for data to be appended, then smoothly scroll down
      setTimeout(() => {
        if (tableRef.current) {
          const newHeight = tableRef.current.scrollHeight;
          tableRef.current.scrollTo({
            top: scrollTop + (newHeight - prevHeight), // Adjust scroll to maintain position
            behavior: 'smooth',
          });
        }
      }, 300); // Adjust delay based on fetch time
    }
  };

  return (
    <div>
      <Paper sx={styles.paper}>
        <Box sx={styles.gridConatiner}>
          {rightPanelData.map((item) => {
            let data = member?.currentMemberDetails?.[item?.key] || '';
            return (
              <Box key={item.key} sx={styles.gridItem}>
                <AdminDetailListItem label={item.value} value={data} type={item?.type} />
              </Box>
            );
          })}
        </Box>
      </Paper>

      <Box
        ref={tableRef}
        sx={{
          maxHeight: '500px',
          overflowY: 'auto',
          border: '1px solid #ddd',
          position: 'relative',
        }}
        onScroll={handleScroll}>
        {loading && pageNo === 0 && <Loading isOpen={loading} />}
        {error && (
          <SnackBar
            errorMessage={error}
            isSnackBarOpen={!!error}
            closeSnackBar={() => setError(null)}
          />
        )}

        {!loading && !error && tableData.length > 0 ? (
          <Box sx={{position: 'relative'}}>
            <Box
              sx={{
                position: 'sticky',
                top: 0,
                background: '#fff',
                zIndex: 100,
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
              }}>
              <TableView
                {...getAddressGridData(tableData, sortOrder, currentSortCriteria, true)}
                onRowClick={() => {}}
                sortTable={onSortIconClick}
              />
            </Box>
          </Box>
        ) : (
          !loading && <NoResults text="No addresses found." />
        )}

        {loading && (
          <Box sx={{textAlign: 'center', padding: '10px'}}>
            <Loading isOpen={true} />
          </Box>
        )}
      </Box>
    </div>
  );
};

export default TabAdminProfile;
