import {globalConfig} from 'Config/config';
import {Mutations, Queries} from 'GQL/GQLConstants';
import {gqlCall, GQLType} from 'GQL/utils';

const gqlSearchSRByID = async (payload, headers) => {
  const {
    BFF: {estBFF},
  } = globalConfig._config;

  try {
    const response = await gqlCall(
      GQLType.QUERY,
      Queries.SR_SEARCH,
      payload,
      headers,
      estBFF,
      false,
    );
    return response;
  } catch (error) {
    return {error};
  }
};

const gqlSearchSRByType = async (payload, headers) => {
  const {
    BFF: {estBFF},
  } = globalConfig._config;

  try {
    const response = await gqlCall(
      GQLType.QUERY,
      Queries.SR_SEARCH_BY_TYPE,
      payload,
      headers,
      estBFF,
      false,
    );
    return response;
  } catch (error) {
    return {error};
  }
};

const gqlAdminSRDashboardSearch = async (payload, headers) => {
  const {
    BFF: {estBFF},
  } = globalConfig._config;

  try {
    const response = await gqlCall(
      GQLType.QUERY,
      Queries.GET_ADMIN_SR_DASHBOARD_SEARCH,
      payload,
      headers,
      estBFF,
      false,
    );
    return response;
  } catch (error) {
    return {error};
  }
};

const gqlTransactionDashboardSearch = async (payload, headers) => {
  const {
    BFF: {astBFF},
  } = globalConfig._config;

  try {
    const response = await gqlCall(
      GQLType.QUERY,
      Queries.GET_ALL_CLAIMED_OFFER,
      payload,
      headers,
      astBFF,
      false,
    );
    return response;
  } catch (error) {
    return {error};
  }
};

const gqlSRSearchByClaimId = async (payload, headers) => {
  const {
    BFF: {astBFF},
  } = globalConfig._config;

  try {
    const response = await gqlCall(
      GQLType.QUERY,
      Queries.GET_SR_BY_CLAIM_ID,
      payload,
      headers,
      astBFF,
      false,
    );
    return response;
  } catch (error) {
    return {error};
  }
};

const gqlGetPaymentDetails = async (payload, headers) => {
  const {
    BFF: {astBFF},
  } = globalConfig._config;

  try {
    const response = await gqlCall(
      GQLType.QUERY,
      Queries.GET_PAYMENT_DETAILS_BY_BILLING_ID,
      payload,
      headers,
      astBFF,
      false,
    );
    return response;
  } catch (error) {
    return {error};
  }
};

const gqlGetMemberAddress = async (payload, headers) => {
  const {
    BFF: {estBFF},
  } = globalConfig._config;

  try {
    const response = await gqlCall(
      GQLType.QUERY,
      Queries.GET_MEMBER_ADDRESS_BY_USER_ID,
      payload,
      headers,
      estBFF,
      false,
    );
    return response;
  } catch (error) {
    return {error};
  }
};

const gqlCashbackAmountEstimation = async (payload, headers) => {
  const {
    BFF: {astBFF},
  } = globalConfig._config;

  try {
    const response = await gqlCall(
      GQLType.QUERY,
      Queries.CASHBACK_AMOUNT_ESTIMATION,
      payload,
      headers,
      astBFF,
      false,
    );
    return response;
  } catch (error) {
    return {error};
  }
};

const gqlCashbackByClaimId = async (payload, headers) => {
  const {
    BFF: {estBFF},
  } = globalConfig._config;

  try {
    const response = await gqlCall(
      GQLType.QUERY,
      Queries.GET_CASHBACK_BY_CLAIM_ID,
      payload,
      headers,
      estBFF,
      false,
    );
    return response;
  } catch (error) {
    return {error};
  }
};

const gqlCreateSR = async (payload, headers) => {
  const {
    BFF: {estBFF},
  } = globalConfig._config;
  try {
    const response = await gqlCall(
      GQLType.MUTATION,
      Mutations.CREATE_SR,
      payload,
      headers,
      estBFF,
      false,
    );

    return response;
  } catch (error) {
    return {error};
  }
};

const gqlUpdateSR = async (payload, headers) => {
  const {
    BFF: {estBFF},
  } = globalConfig._config;
  try {
    const response = await gqlCall(
      GQLType.MUTATION,
      Mutations.UPDATE_SR,
      payload,
      headers,
      estBFF,
      false,
    );

    return response;
  } catch (error) {
    return {error};
  }
};
const gqlOwnerDetails = async (headers) => {
  const {
    BFF: {estBFF},
  } = globalConfig._config;

  try {
    const response = await gqlCall(
      GQLType.QUERY,
      Queries.GET_ALL_USERS,
      null,
      headers,
      estBFF,
      false,
    );

    return response.data.getAllUsers;
  } catch (error) {
    return {error};
  }
};

const gqlTenantServiceMapping = async (payload, headers) => {
  const {
    BFF: {estBFF},
  } = globalConfig._config;

  try {
    const response = await gqlCall(
      GQLType.QUERY,
      Queries.GET_TENANT_SERVICES_MAPPING,
      payload,
      headers,
      estBFF,
      false,
    );

    return response;
  } catch (error) {
    return {error};
  }
};

const gqlFetchTranscript = async (payload, headers) => {
  const {
    BFF: {estBFF},
  } = globalConfig._config;

  try {
    const response = await gqlCall(
      GQLType.QUERY,
      Queries.GET_TRANSCRIPT,
      payload,
      headers,
      estBFF,
      false,
    );

    return response;
  } catch (error) {
    return {error};
  }
};

const gqlPartnerJump = async (payload, headers) => {
  const {
    BFF: {estBFF},
  } = globalConfig._config;

  try {
    const response = await gqlCall(
      GQLType.QUERY,
      Queries.PARTNER_JUMP,
      payload,
      headers,
      estBFF,
      false,
    );

    return response;
  } catch (error) {
    return {error};
  }
};

const gqlResetPassword = async (payload, headers) => {
  const {
    BFF: {estBFF},
  } = globalConfig._config;

  try {
    const response = await gqlCall(
      GQLType.MUTATION,
      Mutations.RESET_PASSWORD,
      payload,
      headers,
      estBFF,
      false,
    );

    return response;
  } catch (error) {
    return {error};
  }
};

const gqlResetWebEnable = async (payload, headers) => {
  const {
    BFF: {estBFF},
  } = globalConfig._config;

  try {
    const response = await gqlCall(
      GQLType.QUERY,
      Queries.RESET_WEBENABLE,
      payload,
      headers,
      estBFF,
      false,
    );

    return response;
  } catch (error) {
    return {error};
  }
};

const gqlFetchAllMetadata = async (payload, headers) => {
  const {
    BFF: {estBFF},
  } = globalConfig._config;

  try {
    const response = gqlCall(
      GQLType.QUERY,
      Queries.GET_ALL_METADATA,
      payload,
      headers,
      estBFF,
      false,
    );

    return response;
  } catch (error) {
    return {error};
  }
};

const gqlCountries = async (payload, headers) => {
  const {
    BFF: {estBFF},
  } = globalConfig._config;

  try {
    const response = await gqlCall(
      GQLType.QUERY,
      Queries.GET_COUNTRIES,
      payload,
      headers,
      estBFF,
      false,
    );

    return response;
  } catch (error) {
    return {error};
  }
};

export {
  gqlSearchSRByID,
  gqlSearchSRByType,
  gqlCreateSR,
  gqlOwnerDetails,
  gqlTenantServiceMapping,
  gqlFetchTranscript,
  gqlUpdateSR,
  gqlPartnerJump,
  gqlResetPassword,
  gqlResetWebEnable,
  gqlFetchAllMetadata,
  gqlCountries,
  gqlAdminSRDashboardSearch,
  gqlTransactionDashboardSearch,
  gqlSRSearchByClaimId,
  gqlGetPaymentDetails,
  gqlCashbackAmountEstimation,
  gqlCashbackByClaimId,
  gqlGetMemberAddress,
};
