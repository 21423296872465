import {Box, Typography} from '@mui/material';
import {SnackBar, Wrapper} from 'Components';
import Loading from 'Components/Loading';
import TableView from 'Components/TableView';
import {useSnackBar} from 'Hooks';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {updateTenantID} from 'Redux/Slices/login';
import {fetchMemberDetails, setCurrentMemberBenefitDetails} from 'Redux/Slices/selectedMember';
import {isUpdateSRList} from 'Redux/Slices/SRListFilter';
import {getTenantServiceMapping} from 'Redux/Slices/TenantServicesMappings';
import globalSpacing from 'Theme/Spacing';
import {SEARCH_NO_RESULTS_ERROR, CDP_ALLOWED_TENANT_ID} from 'Utils/constants/genericConstants';
import {DASHBOARD} from 'Utils/constants/Paths';
import {SortOrder} from 'Utils/enums';
import {getErrorMsg} from 'Utils/helpers/ErrorHandler';
import {resetReduxOnSearch} from 'Utils/helpers/searchHelper';
import {MEMBER_SEARCH} from '../searchConstants';
import {getMemberResultGridData} from './MultipleResultsGridSource';
import {JsonToCsv} from 'Components';

const MultipleResults = ({results}) => {
  const tenantConfig = useSelector((state: any) => state?.login?.tenantConfig);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToDashboard = async (member) => {
    setLoading(true);
    const variable = {
      memberId: member?.memberId,
      membershipId: member?.membershipId ?? member?.memberId,
      ...(CDP_ALLOWED_TENANT_ID.includes(String(member?.tenantID)) && {userId: member?.userId}),
    };
    if (member?.tenantID) {
      dispatch(updateTenantID(member?.tenantID));
    }
    const response = await dispatch(fetchMemberDetails({variable})).unwrap();
    if (response?.error) {
      setError(getErrorMsg(response.error));
    } else if (response) {
      dispatch(setCurrentMemberBenefitDetails(tenantConfig?.additionalBenefits || '[]'));
      resetReduxOnSearch(dispatch);
      dispatch(
        isUpdateSRList({
          isRefreshSRList: true,
          filterType: 'Recent SRs',
        }),
      );
      dispatch(getTenantServiceMapping({packageId: response.packageId}));
      navigate(DASHBOARD, {replace: true});
    } else {
      setError(SEARCH_NO_RESULTS_ERROR);
    }
    setLoading(false);
  };

  const [tableData, setTableData] = useState<any[]>([]);

  useEffect(() => {
    const tempTableData: any = [];
    (results || []).forEach((tableData) => {
      tempTableData.push({
        externalRefId: tableData.externalRefId,
        memberNumber: tableData.memberNumber,
        firstName: tableData.firstName,
        lastName: tableData.lastName,
        memberId: tableData.memberId,
        packageName: tableData.packageName,
        postalCode: tableData.postalCode || null,
        status: tableData.status,
        membershipId: tableData.membershipId,
        tenantID: tableData.tenantId,
        membershipStatus: tableData.membershipStatus,
        clientMobile: tableData.clientMobile,
        primaryEmail: tableData.primaryEmail,
        userId: tableData.userId,
      });
    });

    setTableData(tempTableData);
  }, [results]);

  if (loading) return <Loading isOpen={true} />;
  return (
    <Wrapper>
      <Box mb={globalSpacing.l} mt={globalSpacing.m}>
        <Typography variant="subtitle1">{MEMBER_SEARCH.multiple_search_results.heading}</Typography>
        {results?.length && <JsonToCsv jsonData={results} />}
        <TableView
          {...getMemberResultGridData(tableData, SortOrder.DESCENDING, '', false)}
          onRowClick={(rowData) => {
            goToDashboard(rowData);
          }}
        />
      </Box>
      {error && (
        <SnackBar
          errorMessage={error}
          isSnackBarOpen={isSnackBarOpen}
          closeSnackBar={closeSnackBar}
        />
      )}
    </Wrapper>
  );
};

MultipleResults.propTypes = {
  results: PropTypes.array.isRequired,
};

export default MultipleResults;
