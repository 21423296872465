import {gql} from '@apollo/client';
import {
  getBrandingData,
  getMemberByExtID,
  getTransactions,
  getTransactionsByClaimDate,
  getTransactionsByType,
  getTransactionsByClaimDateAndType,
  getTransactionDetails,
  getGiftingInfo,
  getGiftingHistory,
  submitQuery,
  submitUpdateQuery,
  searchSrDashboard,
  searchMember,
  getMemberDetails,
  srSearch,
  srSearchByType,
  createSR,
  updateSR,
  getTenantServicesMappings,
  getAllMetadata,
  getMemberCashbackEligibility,
  getConversionRate,
  getAllUsers,
  getTranscript,
  partnerJump,
  get_countries,
  resetWebEnable,
  get_metadata,
  getGMClaimedOrderHistory,
  getImpersonateToken,
  getPaymentVehiclesDetails,
  payment,
  updatePayment,
  getfilteredGMGiftCards,
  getMemberRewardsInfo,
  getAdminSRDashboardSearch,
  getAllClaimedOffer,
  getMemberCahbackRemaingbooking,
  linkTransactionWithSR,
  getServiceRequestByClaimId,
  getPaymentDetails,
  cashbackAmountEstimation,
  cashbackByClaimId,
  getMemberAddress,
} from './Queries';

import {
  resetPassword,
  setMemberGift,
  createPaymentDetails,
  reFulFillClaim,
  buyGiftCard,
} from './Mutations';

export const Queries = {
  GET_BRANDING_DATA: gql`
    ${getBrandingData}
  `,
  GET_MEMBER_BY_EXT_ID: gql`
    ${getMemberByExtID}
  `,
  SEARCH_MEMBER: gql`
    ${searchMember}
  `,
  GET_MEMBER_DETAILS: gql`
    ${getMemberDetails}
  `,
  GET_TRANSACTIONS: gql`
    ${getTransactions}
  `,
  GET_TRANSACTIONS_DATE: gql`
    ${getTransactionsByClaimDate}
  `,
  GET_TRANSACTIONS_TYPE: gql`
    ${getTransactionsByType}
  `,
  GET_TRANSACTIONS_DATE_TYPE: gql`
    ${getTransactionsByClaimDateAndType}
  `,
  GET_TRANSACTION_DETAILS: gql`
    ${getTransactionDetails}
  `,
  GET_GIFTING_INFO: gql`
    ${getGiftingInfo}
  `,
  GET_GIFTING_HISTORY: gql`
    ${getGiftingHistory}
  `,
  SUBMIT_QUERY: gql`
    ${submitQuery}
  `,
  SUBMIT_UPDATE_QUERY: gql`
    ${submitUpdateQuery}
  `,
  GIVE_GIFT: gql`
    ${setMemberGift}
  `,
  SEARCH_SR_DASHBOARD: gql`
    ${searchSrDashboard}
  `,
  SR_SEARCH: gql`
    ${srSearch}
  `,
  SR_SEARCH_BY_TYPE: gql`
    ${srSearchByType}
  `,
  GET_ALL_METADATA: gql`
    ${getAllMetadata}
  `,
  GET_TENANT_SERVICES_MAPPING: gql`
    ${getTenantServicesMappings}
  `,
  GET_MEMBER_CASHBACK_ELIGIBILITY: gql`
    ${getMemberCashbackEligibility}
  `,
  GET_CONVERSION_RATE: gql`
    ${getConversionRate}
  `,
  GET_ALL_USERS: gql`
    ${getAllUsers}
  `,
  GET_TRANSCRIPT: gql`
    ${getTranscript}
  `,
  PARTNER_JUMP: gql`
    ${partnerJump}
  `,
  GET_COUNTRIES: gql`
    ${get_countries}
  `,
  RESET_WEBENABLE: gql`
    ${resetWebEnable}
  `,
  GET_METADATA: gql`
    ${get_metadata}
  `,
  GM_CLAIMED_ORDER_HISTORY: gql`
    ${getGMClaimedOrderHistory}
  `,
  GET_IMPERSONATED_TOKEN: gql`
    ${getImpersonateToken}
  `,
  GET_PAYMENT_CARD_DETAILS: gql`
    ${getPaymentVehiclesDetails}
  `,
  CHARGE_PAYMENT: gql`
    ${payment}
  `,
  UPDATE_PAYMENT: gql`
    ${updatePayment}
  `,
  GET_FILTERED_GMGIFTCARDS: gql`
    ${getfilteredGMGiftCards}
  `,
  GET_MEMBER_REWARDS_INFO: gql`
    ${getMemberRewardsInfo}
  `,
  GET_ADMIN_SR_DASHBOARD_SEARCH: gql`
    ${getAdminSRDashboardSearch}
  `,
  GET_ALL_CLAIMED_OFFER: gql`
    ${getAllClaimedOffer}
  `,
  GET_MEMBER_REMAING_CASHBACK: gql`
    ${getMemberCahbackRemaingbooking}
  `,
  LINK_TRANSACTION_WITH_SR: gql`
    ${linkTransactionWithSR}
  `,
  GET_SR_BY_CLAIM_ID: gql`
    ${getServiceRequestByClaimId}
  `,
  GET_PAYMENT_DETAILS_BY_BILLING_ID: gql`
    ${getPaymentDetails}
  `,
  GET_MEMBER_ADDRESS_BY_USER_ID: gql`
    ${getMemberAddress}
  `,
  CASHBACK_AMOUNT_ESTIMATION: gql`
    ${cashbackAmountEstimation}
  `,
  GET_CASHBACK_BY_CLAIM_ID: gql`
    ${cashbackByClaimId}
  `,
};

export const Mutations = {
  CREATE_SR: gql`
    ${createSR}
  `,
  UPDATE_SR: gql`
    ${updateSR}
  `,
  RESET_PASSWORD: gql`
    ${resetPassword}
  `,
  SAVE_OR_UPDATE_PAYMENT_VEHICLE: gql`
    ${createPaymentDetails}
  `,
  REFULLFILL_CLAIM: gql`
    ${reFulFillClaim}
  `,
  BUY_GIFT_CARD: gql`
    ${buyGiftCard}
  `,
};
