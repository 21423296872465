import {Box, Typography} from '@mui/material';
import {SnackBar, Wrapper} from 'Components';
import Loading from 'Components/Loading';
import TableView from 'Components/TableView';
import {useSnackBar} from 'Hooks';
import {MEMBER_SEARCH} from 'Pages/SearchMember/searchConstants';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {updateTenantID} from 'Redux/Slices/login';
import {fetchMemberDetails, setCurrentMemberBenefitDetails} from 'Redux/Slices/selectedMember';
import {searchSRByID} from 'Redux/Slices/selectedSR';
import {isUpdateSRList} from 'Redux/Slices/SRListFilter';
import {getTenantServiceMapping} from 'Redux/Slices/TenantServicesMappings';
import globalSpacing from 'Theme/Spacing';
import {FORMAT_TYPE} from 'Utils/constants/DateFormat';
import {CDP_ALLOWED_TENANT_ID, SEARCH_NO_RESULTS_ERROR} from 'Utils/constants/genericConstants';
import {DASHBOARD} from 'Utils/constants/Paths';
import {SortOrder, TabPanelType} from 'Utils/enums';
import {dateConversionIsoToLocal} from 'Utils/helpers/DateValidation';
import {getErrorMsg} from 'Utils/helpers/ErrorHandler';
import {resetReduxOnSearch} from 'Utils/helpers/searchHelper';
import {sortTable} from 'Utils/helpers/SRListHelpers';
import {getSRDashboardListGirdData} from './SRDashboardListGridDataSource';
import {JsonToCsv} from 'Components';

const SRDashboardList = ({results}) => {
  const [error, setError] = useState('');
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tenantConfig = useSelector((state: any) => state?.login?.tenantConfig);
  const loginDetails = useSelector((state: any) => state?.login);
  const [loading, setLoading] = useState(false);

  const [currentSortCriteria, setCriteriaSort] = useState('membership');
  const [sortOrder, setSortOrder] = useState(SortOrder.DESCENDING);
  const [tableData, setTableData] = useState<any[]>([]);

  useEffect(() => {
    const tempTableData: any = [];
    (results || []).forEach((tableData) => {
      tempTableData.push({
        membership: tableData?.member?.membershipNumber,
        srType: tableData.type,
        subType: tableData.subType,
        status: tableData.status,
        owner: tableData?.owner?.userName || '',
        createdDate: dateConversionIsoToLocal(
          tableData?.audit?.createdOn,
          FORMAT_TYPE.DATE_TIME,
          '',
          tenantConfig?.locale,
        ),
        modifiedDate: dateConversionIsoToLocal(
          tableData?.audit?.modifiedOn,
          FORMAT_TYPE.DATE_TIME,
          '',
          tenantConfig?.locale,
        ),
        summary: tableData.summary,
        srData: tableData,
      });
    });

    let resp = sortTable(
      tempTableData,
      'membership',
      'membership',
      SortOrder.ASCENDING,
      tenantConfig?.locale,
    );
    setCriteriaSort(resp.currentSortCriteria);
    setTableData(resp.tableData);
    setSortOrder(resp.newSortOrder);

    setTableData(tempTableData);
  }, [results]);

  const goToDashboard = async (payload, userId) => {
    setLoading(true);
    let response = await dispatch(searchSRByID(payload)).unwrap();
    if (response?.error) {
      setError(getErrorMsg(response.error));
    } else if (response && response?.data?.getServiceRequestById) {
      const variable = {
        memberId: response.data.getServiceRequestById.member?.id,
        membershipId:
          response.data.getServiceRequestById.member?.membershipId ??
          response.data.getServiceRequestById.member?.id,
        ...(CDP_ALLOWED_TENANT_ID.includes(String(loginDetails?.tenantID)) && {userId: userId}),
      };
      if (response.data.getServiceRequestById.member?.tenantId) {
        await dispatch(updateTenantID(response.data.getServiceRequestById.member?.tenantId));
      }
      const memberResponse = await dispatch(fetchMemberDetails({variable})).unwrap();
      if (memberResponse?.error) {
        setError(getErrorMsg(memberResponse.error));
      } else if (memberResponse) {
        dispatch(setCurrentMemberBenefitDetails(tenantConfig?.additionalBenefits || '[]'));
        resetReduxOnSearch(dispatch);
        dispatch(
          isUpdateSRList({
            isRefreshSRList: true,
            filterType: 'Recent SRs',
          }),
        );
        await dispatch(getTenantServiceMapping({packageId: memberResponse.packageId}));
        navigate(DASHBOARD, {state: {tab: TabPanelType.serviceRequests, isSRDialogView: true}});
      } else {
        setError(SEARCH_NO_RESULTS_ERROR);
      }
      setLoading(false);
    } else {
      setError(SEARCH_NO_RESULTS_ERROR);
    }
  };

  const onSortIconClick = (sortCriteria: string) => {
    let resp = sortTable(
      tableData,
      sortCriteria,
      currentSortCriteria,
      sortOrder,
      tenantConfig?.locale,
    );
    setCriteriaSort(resp.currentSortCriteria);
    setTableData(resp.tableData);
    setSortOrder(resp.newSortOrder);
  };

  if (loading) return <Loading isOpen={true} />;
  return (
    <Wrapper>
      <Box mb={globalSpacing.l} mt={globalSpacing.m}>
        <Typography variant="subtitle1">{MEMBER_SEARCH.multiple_search_results.heading}</Typography>
        {results?.length && <JsonToCsv jsonData={results} />}
        <TableView
          {...getSRDashboardListGirdData(tableData, SortOrder.DESCENDING, '', true)}
          onRowClick={(rowData) => {
            let {srData} = rowData;
            let srPayload = {
              id: srData.id,
            };
            goToDashboard(srPayload, srData?.userId);
          }}
          sortTable={onSortIconClick}
        />
      </Box>
      {error && (
        <SnackBar
          errorMessage={error}
          isSnackBarOpen={isSnackBarOpen}
          closeSnackBar={closeSnackBar}
        />
      )}
    </Wrapper>
  );
};

export default SRDashboardList;
