import Form from 'Components/Form/FormWrapper';
import React, {useEffect, useState} from 'react';
import {
  bookCashbackEventDetails,
  bookCashbackSRSection,
  buttonList,
  hiddenAddressFields,
} from './BookCashbackEvent';
import {initialValues, validationSchema} from './bookCashbackEventSchema';
import {Box, Button, Checkbox, Grid, Paper, Stack, Typography, useTheme} from '@mui/material';
import SnackBar from 'Components/SnackBar';
import Wrapper from 'Components/Wrapper';
import {Queries} from 'GQL/GQLConstants';
import {gqlCall} from 'GQL/utils';
import {useSnackBar} from 'Hooks';
import {isUpdateSRList} from 'Redux/Slices/SRListFilter';
import {searchSRByID} from 'Redux/Slices/selectedSR';
import {createSR, updateSR} from 'Redux/Slices/serviceRequest';
import globalSpacing from 'Theme/Spacing';
import {
  CREATE_SR_CANCEL_ERROR,
  SR_STATUS,
  SR_TYPES,
  UPDATE_SR_ERROR,
} from 'Utils/constants/genericConstants';
import {
  getBookingFee,
  getCurrency,
  getDeliveryAddress,
  getNotificationParams,
  getSeatDetails,
  getTicketItemDetails,
  getTotalActualPrice,
  hideShowFormFields,
  isLastCashbackBooking,
  isMemberEligibleForCashback,
  isPaymentSuccessful,
} from 'Utils/helpers/BookCashbckSR';
import {
  dateConversionIsoToLocal,
  dateConversionToISOFormatIgnoreTimeZone,
  getDate,
} from 'Utils/helpers/DateValidation';
import {getMemberCashbackEligibility} from 'Utils/helpers/MemberDetails';
import {parseToFloat, roundOff} from 'Utils/helpers/Numeric';
import {updateFormContent} from 'Utils/helpers/SRDetailViewHelper';
import {isDisableFormFields, sortTable} from 'Utils/helpers/SRListHelpers';
import {useDispatch, useSelector} from 'react-redux';
import AddTicketDialog from './components/AddTicketDialog';
import TicketListView from './components/TicketListView';
import {gqlTransactionDashboardSearch} from 'GQL/MethodsBFF/ServiceRequest';
import {v4 as uuidv4} from 'uuid';
// import LinkTransactionDialog from './components/LinkTransactionDialog';
import TableView from 'Components/TableView';
import {getTRDashboardListGirdData} from 'Pages/TransactionsDashboard/components/TRDashboardListGridDataSource';
import {SortOrder} from 'Utils/enums';
import {FORMAT_TYPE} from 'Utils/constants/DateFormat';

interface IProps {
  srFormDetails?: any;
  closeDialogMethod?: any;
}

const BookCashbackEventSR: React.FC<IProps> = (props) => {
  const theme = useTheme();
  const [bcformFields, setBCFormFields] = useState<any>(bookCashbackSRSection);
  const [bcEventformFields, setBCEventFormFields] = useState<any>(bookCashbackEventDetails); // setBCEventFormFields
  const [buttonSection, setButtonSection] = useState(buttonList);
  const [initialFormValues, setInitialFormValues] = useState<any>({...initialValues});
  const ownerID = useSelector((state: any) => state?.login?.userName);
  const selectedSR = useSelector((state: any) => state.selectedSR?.getServiceRequestById);
  const tenantConfig = useSelector((state: any) => state?.login?.tenantConfig);
  const {membershipEnabled} = tenantConfig;
  const selectedMemberDetails = useSelector((state: any) => state?.selectedMember).selectedMember;
  const [ticketItemDetails, setTicketItemDetails] = useState<any[]>([]);
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [isSnackBarOpen, closeSnackBar] = useSnackBar(error);
  const [openAddTicketDialog, isOpenTicketDialog] = useState(false);
  const [ticket, setTicket] = useState<any[]>([]);
  const [currentTicketItemId, setCurrentTicketItemId] = useState<string>('');
  const [deletedTicketLineIds] = useState<string[]>([]);
  const [conversionRate, setConversionRate] = useState<number>(1);
  const loginDetails = useSelector((state: any) => state?.login);
  // const [multipleResults, setMultipleResults] = useState<any>(null);
  // const [isLinkTransactionDisabled, setIsLinkTransactionDisabled] = useState<boolean>(true);
  // const [isLinkTransactionDialogOpen, setIsLinkTransactionDialogOpen] = useState(false);
  const [transactionTableData, setTransactionTableData] = useState<any[]>([]);
  const [noCashback, setNoCashback] = useState<boolean>(false);

  let memberCashbackEligibility;

  const fetchTransactions = async () => {
    let payload = {
      tenantId: loginDetails?.tenantID,
      claimId: selectedSR.claimId, //'169', //selectedSR.claimId,
      userGroupId: selectedMemberDetails?.membershipId,
    };
    const headers = {
      'tenant-id': loginDetails?.tenantID,
      // 'tenant-id': 5000005,
      'x-correlation-id': uuidv4(),
      Authorization: `Bearer ${loginDetails?.loginInfo?.access_token}`,
    };
    let response = await gqlTransactionDashboardSearch(payload, headers);
    let data: any = response.data.getAllClaimedOffer[0].claimedOffers;
    let tempTableData: any = [];

    (data || []).forEach((tableData) => {
      tempTableData.push({
        membership: tableData?.customerId,
        id: tableData.id,
        claimType: tableData.claimType,
        status: tableData.status,
        //owner: tableData?.owner?.userName || '',
        createdDate: dateConversionIsoToLocal(
          tableData?.claimDateTime,
          FORMAT_TYPE.DATE_TIME,
          '',
          tenantConfig?.locale,
        ),
        offerType: tableData.offerType,
        //summary: tableData.summary,
        transactionData: tableData,
      });
    });

    let resp = sortTable(
      tempTableData,
      'membership',
      'membership',
      SortOrder.ASCENDING,
      tenantConfig?.locale,
    );

    setTransactionTableData(resp.tableData);
  };

  useEffect(() => {
    //  getCashbackEligibility();
    if (selectedSR) {
      if (selectedSR.claimId) {
        fetchTransactions();
        // setIsLinkTransactionDisabled(true);
      }
      // else {
      // setIsLinkTransactionDisabled(false);
      // }
      updateSRFormValues();
      if (checkSrStutus()) {
        setformFields(true);
      } else {
        setformFields(false);
      }
    } else {
      setformFields(false);
      setBCFormFields(bookCashbackSRSection);
      setBCEventFormFields(bookCashbackEventDetails);
      selectedMemberDetials();
      setBCFormFields(hideShowFormFields(bcformFields, hiddenAddressFields, true));
    }
    const ticketLineId =
      (selectedSR?.additionalData?.units &&
        selectedSR?.additionalData.units.length > 0 &&
        selectedSR?.additionalData.units[0]?.additionalInfo?.ticketLineId) ||
      '';
    setCurrentTicketItemId(ticketLineId);
  }, [selectedSR]);

  const getCashbackEligibility = async (formValues, additionalData?) => {
    if (checkSrStutus()) {
      formValues.isEligibleForCashback = additionalData?.isEligibleForCashback === false;
      formValues.isLastCashbackBooking = additionalData?.isLastCashbackBooking;
    } else {
      const headers = {
        'tenant-id': loginDetails?.tenantID,
        'user-access-token': loginDetails?.loginInfo?.access_token,
        'client-access-token': loginDetails?.loginInfo?.service_token,
      };
      memberCashbackEligibility = await getMemberCashbackEligibility(
        selectedMemberDetails,
        headers,
      );
      formValues.isEligibleForCashback =
        isMemberEligibleForCashback(memberCashbackEligibility?.cashback) === false;
      formValues.isLastCashbackBooking = isLastCashbackBooking(memberCashbackEligibility?.cashback);
    }
    return true;
  };

  const selectedMemberDetials = async () => {
    const selectedMember = selectedMemberDetails.currentMemberDetails;
    initialFormValues.phoneNumber = selectedMember?.homePhone;
    initialFormValues.mobileNumber = selectedMember?.mobilePhone;
    initialFormValues.alternatePhoneNumber = selectedMember?.businessPhone;
    initialFormValues.alternateEmail = selectedMember?.alternateEmail
      ? selectedMember?.alternateEmail
      : selectedMember?.clientEmail;
    if (await getCashbackEligibility(initialFormValues)) setInitialFormValues(initialFormValues);
  };
  const updateSRFormValues = async () => {
    const {additionalData} = selectedSR;
    const formValues = {
      ...additionalData,
      venueName: additionalData?.details?.venue,
      eventName: additionalData?.details?.eventName,
      eventCategory: additionalData?.details?.category,
      eventDateTime: getDate(additionalData?.details?.dateTime),
      ...additionalData?.deliveryAddress,
      addressLineOne: additionalData?.deliveryAddress?.addressLine1,
      addressLineTwo: additionalData?.deliveryAddress?.addressLine2,
      addressLineThree: additionalData?.deliveryAddress?.addressLine3,
      seatDetails: getSeatDetails(additionalData.details?.seats),
    };
    if (await getCashbackEligibility(formValues, additionalData)) setInitialFormValues(formValues);
    setTicketItemDetails(additionalData?.units);
    setConversionRate(selectedSR.additionalData.conversionRate || 1);
    setBCFormFields(
      hideShowFormFields(bcformFields, hiddenAddressFields, formValues.isUseMailingAddress),
    );
  };
  const handleFormSubmit = (values) => {
    createSrRequest(values);
  };

  const createAdditionalData = async (formValues) => {
    const additionalData = selectedSR?.additionalData;

    const selectedMember = selectedMemberDetails.currentMemberDetails;
    let solCurrency = getCurrency(selectedMemberDetails);
    let srCurrency = formValues.currency;
    if (solCurrency !== srCurrency) {
      if (conversionRate === 1) {
        const request = {
          fromCurrency: srCurrency,
          toCurrency: solCurrency,
        };
        const resp = await gqlCall(Queries.GET_CONVERSION_RATE, request);
        if (resp && resp.geCurrencyConversionRate) {
          let rate = parseToFloat(resp.geCurrencyConversionRate);
          setConversionRate(rate);
        }
      }
    } else {
      setConversionRate(1);
    }

    const totalAmount = await getTotalActualPrice(
      ticketItemDetails,
      formValues.postageFee,
      conversionRate,
    );

    return {
      source: 'ng-engage',
      bookingReference: formValues.bookingReference,
      currency: solCurrency,
      unitCurrency: formValues.currency,
      isAvailCashback: !noCashback,
      customerEmail: selectedMember.primaryEmail,
      customerFirstName: selectedMember.firstName,
      customerLastName: selectedMember.lastName,
      customerPhoneNumber: selectedMember.mobilePhone,
      service: props.srFormDetails?.serviceType,
      details: {
        dateTime: dateConversionToISOFormatIgnoreTimeZone(new Date(formValues.eventDateTime)),
        eventName: formValues.eventName,
        category: formValues.eventCategory,
        seats: [
          {
            block: '',
            row: '',
            seat: '',
            additionalDetails: formValues.seatDetails,
          },
        ],
        venue: formValues.venueName,
      },
      totalBookingFee: roundOff(
        getBookingFee(ticketItemDetails, 'bookingFee', 'quantity') * conversionRate,
        2,
      ),
      postageFee: roundOff(formValues.postageFee, 2),
      units: [...ticketItemDetails],
      totalActualPrice: totalAmount,
      totalPriceAfterCashback: roundOff(parseToFloat(formValues.totalPriceAfterCashback), 2),
      totalCashback: roundOff(parseToFloat(formValues.totalCashback), 2),
      extraCashbackPercentage: parseToFloat(formValues.extraCashbackPercentage),
      extraCashback: roundOff(parseToFloat(formValues.extraCashback), 2),
      payments: additionalData?.payments || [],
      deliveryAddress: getDeliveryAddress(formValues, selectedMember),
      deliveryName: formValues.isUseMailingAddress
        ? selectedMember.firstName + ' ' + selectedMember.lastName
        : formValues.deliveryName,
      deliveryType: formValues.deliveryType,
      ticketProvider: formValues.ticketProvider,
      bookingDate: selectedSR?.id ? additionalData?.bookingDate || '' : '',
      cancellationReason: formValues.cancellationReason,
      isEligibleForCashback: formValues.isEligibleForCashback === false,
      isCashbackProcessed: additionalData?.isCashbackProcessed || false,
      channel: formValues.channel,
      subStatus: formValues.subStatus,
      comments: formValues.comments,
      alternateEmail: formValues.alternateEmail,
      isLastCashbackBooking: formValues.isLastCashbackBooking,
      onSaleRegRequestId: additionalData?.onSaleRegRequestId,
      isUseMailingAddress: formValues.isUseMailingAddress,
      deletedLineIdList: deletedTicketLineIds || [],
      conversionRate: conversionRate,
      onSalePvId: additionalData?.onSalePvId,
    };
  };

  const createSrRequest = async (formValues) => {
    confirmedSRFieldsRequired(false);
    let status = selectedSR?.srStatus || SR_STATUS.OPEN;

    if (formValues?.buttonAction) {
      switch (formValues?.buttonAction) {
        case 'Save':
          status = selectedSR?.srStatus || SR_STATUS.OPEN;
          break;
        case 'Cancel':
          status = SR_STATUS.CANCELLED;
          break;
        case 'Confirmed':
          status = SR_STATUS.CONFIRMED;
          if (!formValues.bookingReference) {
            setError('Please fill the BookingReference details to proceed');

            return false;
          }
          break;
      }
    }
    if (status == SR_STATUS.CANCELLED && formValues.cancellationReason === '') {
      setError(CREATE_SR_CANCEL_ERROR);
      return false;
    }
    if (selectedMemberDetails && selectedMemberDetails.currentMemberDetails) {
      const selectedMember = selectedMemberDetails.currentMemberDetails;
      const notificationParams = getNotificationParams(
        selectedMemberDetails,
        formValues.alternateEmail,
        membershipEnabled,
      );
      if (selectedSR?.id) {
        let updateSRVariable = {
          id: selectedSR?.id,
          requestObj: {
            commands: [
              {
                commandName: 'updateSR',
                commandId: selectedSR?.id,
                ...selectedSR,
                summary: formValues.eventName,
                status: status,
                userId: selectedMember?.userId,
                userGroupId: selectedMemberDetails?.membershipId,
                additionalData: await createAdditionalData(formValues),
                audit: {...selectedSR.audit, modifiedBy: ownerID.toLowerCase()},
              },
            ],
          },
          notificationParams: notificationParams,
        };
        if (status === SR_STATUS.CONFIRMED) {
          if (
            !isPaymentSuccessful(selectedSR) &&
            !formValues.deliveryType &&
            !formValues.seatDetails &&
            !formValues.bookingReference
          ) {
            confirmedSRFieldsRequired(true);
            setError(
              'Please fill the Ticket Delivery, Seat Details, BookingReference details and ensure that the payments are present to proceed',
            );
            return false;
          }
        }

        try {
          let resp = await dispatch(updateSR(updateSRVariable)).unwrap();

          let srUpdateStatus = resp?.data?.updateServiceRequest[0]?.status;
          if (srUpdateStatus?.toUpperCase() === 'SUCCESS') {
            let payload = {
              id: selectedSR?.id,
            };
            await dispatch(searchSRByID(payload)).unwrap();
            props.closeDialogMethod(true);
            dispatch(
              isUpdateSRList({
                isRefreshSRList: true,
              }),
            );
            setError('');
          } else if (srUpdateStatus?.toUpperCase() === 'FAILURE') {
            let srUpdateErrors = resp?.data?.updateServiceRequest?.[0]?.errors;
            setError(srUpdateErrors);
          } else console.log('Something Went Wrong');
        } catch (error: any) {
          setError(UPDATE_SR_ERROR);
        }
      } else {
        let createRequest = {
          serviceRequest: {
            id: null,
            type: props.srFormDetails.srType,
            subType: '',
            member: {
              id: selectedMember.memberId,
              membershipId: selectedMemberDetails.membershipId,
              // membershipNumber: selectedMemberDetails?.membershipNumber || null,
            },
            userId: selectedMember?.userId || null,
            userGroupId: selectedMemberDetails?.membershipId || null,
            owner: null,
            activities: [],
            description: '',
            summary: formValues.eventName,
            status: status,
            additionalData: await createAdditionalData(formValues),
            audit: {
              createdBy: ownerID.toLowerCase(),
            },
          },
          notificationParams: notificationParams,
        };
        let resp = await dispatch(createSR(createRequest)).unwrap();
        if (resp) {
          props.closeDialogMethod(true);
          let payload = {
            id: resp.data.createServiceRequest.id,
          };
          await dispatch(searchSRByID(payload)).unwrap();
          dispatch(
            isUpdateSRList({
              isRefreshSRList: true,
            }),
          );
        }
      }
    }
  };

  const clearMessageOnClose = () => setError('');

  const onUpdateItemClick = (data) => {
    setCurrentTicketItemId(data.ticketLineId);
    setTicket(data);
    isOpenTicketDialog(true);
  };

  const onRemoveItemClick = (data) => {
    setCurrentTicketItemId(data?.ticketLineId);
    if (checkSrStutus()) return;
    if (currentTicketItemId === data?.ticketLineId) {
      setCurrentTicketItemId('');
    }
    let tempArr: any[] = [];
    deletedTicketLineIds.push(data?.ticketLineId);
    for (let i = 0; i < ticketItemDetails.length; i++) {
      if (ticketItemDetails[i]?.additionalInfo?.ticketLineId === data?.ticketLineId) {
        continue;
      }
      tempArr.push(ticketItemDetails[i]);
    }
    setTicketItemDetails(tempArr);
  };

  const handleAddTicketClick = async () => {
    setTicket([]);
    setCurrentTicketItemId('');
    isOpenTicketDialog(true);
  };

  const handleTicketOnSubmit = (ticket) => {
    if (ticket['buttonAction'] === 'Add' || ticket['buttonAction'] === 'Update') {
      if (currentTicketItemId) {
        let tempArr = [...ticketItemDetails];
        let ticketItemIndex = tempArr.findIndex(
          (item) => item?.additionalInfo?.ticketLineId === currentTicketItemId,
        );
        let ticketItem = getTicketItemDetails(ticket, tempArr, noCashback);
        ticketItem.additionalInfo.ticketLineId = currentTicketItemId;
        tempArr[ticketItemIndex] = ticketItem;
        setTicketItemDetails(tempArr);
      } else {
        let tempArr = (ticketItemDetails && [...ticketItemDetails]) || [];
        const ticketItem = getTicketItemDetails(ticket, tempArr, noCashback);
        tempArr.push(ticketItem);
        setCurrentTicketItemId(ticketItem.additionalInfo.ticketLineId);
        setTicketItemDetails(tempArr);
      }
      setTicket([]);
    }
  };

  const handleMailingAddressClick = (e, setField) => {
    setBCFormFields(hideShowFormFields(bcformFields, hiddenAddressFields, e.target.checked));
    let updatedFormContent = updateFormContent(bcformFields, {
      deliveryName: {
        targetAttribute: 'required',
        targetValue: !e.target.checked,
      },
    });
    setBCFormFields(updatedFormContent);
    // if (e.target.checked) {
    //   const selectedMember = selectedMemberDetails.currentMemberDetails;
    //   setField('mobileNumber', selectedMember?.mobilePhone);
    //   setField('alternateEmail', selectedMember?.clientEmail);
    // } else {
    //    setField('mobileNumber', '');
    //   setField('alternateEmail', '');
    // }
  };

  const confirmedSRFieldsRequired = (value) => {
    let updatedFormContent = updateFormContent(bcformFields, {
      deliveryType: {
        targetAttribute: 'required',
        targetValue: value,
      },
    });
    setBCFormFields(updatedFormContent);

    let targetFormFields = {
      bookingReference: {
        targetAttribute: 'required',
        targetValue: value,
      },
      seatDetails: {
        targetAttribute: 'required',
        targetValue: value,
      },
    };

    let updatedEventFormContent = updateFormContent(bcEventformFields, targetFormFields);
    setBCEventFormFields(updatedEventFormContent);
  };

  const checkSrStutus = () => {
    if (
      selectedSR &&
      (selectedSR.type === SR_TYPES.BOOK_CASHBACK_EVENT ||
        selectedSR.type === SR_TYPES.CINEMA_ECODE ||
        selectedSR.type === SR_TYPES.FILM_RENTAL_ECODE) &&
      (selectedSR.status === SR_STATUS.CANCELLED || selectedSR.status === SR_STATUS.CONFIRMED)
    ) {
      return true;
    }
  };
  const customEvents = {
    isUseMailingAddress: {onChange: handleMailingAddressClick},
  };
  const setformFields = (isDisabled) => {
    let disabledFormBCFields = updateFormContent(
      bcformFields,
      isDisableFormFields(bcformFields.fields, isDisabled),
    );
    setBCFormFields(disabledFormBCFields);

    let disabledFormEventFields = updateFormContent(
      bcEventformFields,
      isDisableFormFields(bcEventformFields.fields, isDisabled),
    );
    setBCEventFormFields(disabledFormEventFields);

    const targetButtonFields = {
      saveButton: {
        targetAttribute: 'isDisabled',
        targetValue: isDisabled,
      },
      cancelButton: {
        targetAttribute: 'isDisabled',
        targetValue: isDisabled,
      },
      confirmedButton: {
        targetAttribute: 'isDisabled',
        targetValue: isDisabled,
      },
    };
    let updateButtonList = updateFormContent(buttonSection, targetButtonFields);
    setButtonSection(updateButtonList);

    const fixedDisabledFields = {
      isEligibleForCashback: {
        targetAttribute: 'isDisabled',
        targetValue: true,
      },
      isLastCashbackBooking: {
        targetAttribute: 'isDisabled',
        targetValue: true,
      },
    };
    setBCFormFields(updateFormContent(bcformFields, fixedDisabledFields));

    const eventFixedDisabledFields = {
      bookingDate: {
        targetAttribute: 'isDisabled',
        targetValue: true,
      },
      totalBookingFee: {
        targetAttribute: 'isDisabled',
        targetValue: true,
      },
      totalActualPrice: {
        targetAttribute: 'isDisabled',
        targetValue: true,
      },
      extraCashback: {
        targetAttribute: 'isDisabled',
        targetValue: true,
      },
      totalCashback: {
        targetAttribute: 'isDisabled',
        targetValue: true,
      },
      totalPriceAfterCashback: {
        targetAttribute: 'isDisabled',
        targetValue: true,
      },
    };
    setBCEventFormFields(updateFormContent(bcEventformFields, eventFixedDisabledFields));
  };
  // const handleLinkTransactionClick = () => {
  //   setIsLinkTransactionDialogOpen(true);
  // };

  // const closeLinkTransactionDialog = () => {
  //   setIsLinkTransactionDialogOpen(false);
  // };

  return (
    <Wrapper>
      <SnackBar
        isSnackBarOpen={isSnackBarOpen}
        closeSnackBar={closeSnackBar}
        errorMessage={error}
        clearMessageOnClose={clearMessageOnClose}
      />
      <Form
        onSubmit={handleFormSubmit}
        validationSchema={validationSchema}
        initialValues={initialFormValues}
        section1={bcformFields}
        section2={bcEventformFields}
        buttonStack={buttonList}
        customEvents={customEvents}
      />
      <Paper sx={{marginY: globalSpacing.s}}>
        <Stack spacing={globalSpacing.s} p={globalSpacing.m} mt={0}>
          <Box
            component="span"
            mt={0}
            display="flex"
            justifyContent="space-between"
            alignItems="center">
            <Typography variant="h6">Ticket Details</Typography>

            <Box display="flex" alignItems="center">
              <Checkbox
                checked={ticket?.[0]?.isNoCashback || noCashback}
                sx={{
                  '&.MuiCheckbox-root': {
                    color: theme.palette.primary.dark,
                  },
                  '&.Mui-disabled': {
                    color: theme.palette.text.disabled,
                  },
                }}
                onChange={(e) => setNoCashback(e.target.checked)}
                disabled={ticketItemDetails?.length > 0 ? true : false}
                color="primary"
              />
              <Typography variant="body2" sx={{marginRight: '10px'}}>
                No Cashback
              </Typography>
              <Button variant="contained" onClick={handleAddTicketClick} disabled={checkSrStutus()}>
                Add
              </Button>
            </Box>
          </Box>
          <Grid container spacing={globalSpacing.s}>
            <TicketListView
              tableData={ticketItemDetails}
              onRemoveItemClick={onRemoveItemClick}
              onUpdateItemClick={onUpdateItemClick}
            />
          </Grid>
        </Stack>
        <AddTicketDialog
          openAddTicketDialog={openAddTicketDialog}
          isOpenTicketDialog={isOpenTicketDialog}
          handleOnSubmit={handleTicketOnSubmit}
          ticket={ticket}
          setTicket={setTicket}
        />
      </Paper>

      <Paper sx={{marginY: globalSpacing.s}}>
        <Stack spacing={globalSpacing.s} p={globalSpacing.m} mt={0}>
          {transactionTableData && (
            <>
              <Typography variant="h6">Linked Transactions</Typography>
              <TableView
                {...getTRDashboardListGirdData(
                  transactionTableData,
                  SortOrder.DESCENDING,
                  '',
                  true,
                )}
              />
            </>
          )}
        </Stack>
      </Paper>
    </Wrapper>
  );
};
export default BookCashbackEventSR;
