export const bookEventSRSection = {
  title: 'Service Request Details',
  fields: [
    {
      fieldType: 'text',
      htmlInputType: 'number',
      name: 'phoneNumber',
      id: 'phoneNumber',
      fieldLabel: 'Home Phone',
      required: true,
      xs: 3,
      sm: 3,
      dataTest: 'phoneNumber',
    },
    {
      fieldType: 'text',
      htmlInputType: 'number',
      name: 'mobileNumber',
      id: 'mobileNumber',
      fieldLabel: 'Mobile Phone',
      xs: 3,
      sm: 3,
      dataTest: 'mobileNumber',
    },
    {
      fieldType: 'text',
      htmlInputType: 'number',
      name: 'alternatePhoneNumber',
      id: 'alternatePhoneNumber',
      fieldLabel: 'Alternate Contact',
      xs: 3,
      sm: 3,
      dataTest: 'alternatePhoneNumber',
    },
    {
      fieldType: 'text',
      htmlInputType: 'email',
      name: 'alternateEmail',
      id: 'alternateEmail',
      fieldLabel: 'Other Email',
      required: true,
      xs: 3,
      sm: 3,
      dataTest: 'alternateEmail',
    },
    {
      fieldType: 'select',
      name: 'subStatus',
      id: 'subStatus',
      fieldLabel: 'Sub-status',
      xs: 3,
      sm: 3,
      options: [
        {value: 'Acknowledged', text: 'Acknowledged'},
        {value: 'Activated', text: 'Activated'},
        {value: 'Approved', text: 'Approved'},
        {value: 'Asset unregistered', text: 'Asset unregistered'},
        {value: 'Assigned', text: 'Assigned'},
        {value: 'Authenticated', text: 'Authenticated'},
        {value: 'Auto fax sent', text: 'Auto fax sent'},
        {value: 'Awaiting 3rd party response', text: 'Awaiting 3rd party response'},
        {value: 'Awaiting Activation Date', text: 'Awaiting Activation Date'},
        {value: 'Awaiting authorization', text: 'Awaiting authorization'},
        {value: 'Awaiting Death Certificate', text: 'Awaiting Death Certificate'},
        {value: 'Awaiting File', text: 'Awaiting File'},
        {value: 'Awaiting Member Proof', text: 'Awaiting Member Proof'},
        {value: 'Awaiting member response', text: 'Awaiting member response'},
        {value: 'Awaiting Written Confirmation', text: 'Awaiting Written Confirmation'},
        {value: 'Blocking confirmed by phoneBooked', text: 'Blocking confirmed by phoneBooked'},
        {value: 'Booked', text: 'Booked'},
        {value: 'Call - Outbound', text: 'Call - Outbound'},
        {value: 'Call back created', text: 'Call back created'},
        {value: 'Cancel Confirmed', text: 'Cancel Confirmed'},
        {value: 'Cancel Converted', text: 'Cancel Converted'},
        {value: 'Cancel Deferred', text: 'Cancel Deferred'},
        {value: 'Cancel Discounted', text: 'Cancel Discounted'},
        {value: 'Cancel Downgraded', text: 'Cancel Downgraded'},
        {value: 'Cancel Enforced', text: 'Cancel Enforced'},
        {value: 'Cancel Refunded', text: 'Cancel Refunded'},
        {value: 'Cancel Save - Downgraded', text: 'Cancel Save - Downgraded'},
        {value: 'Cancel Saved', text: 'Cancel Saved'},
        {value: 'Cancel Upgraded', text: 'Cancel Upgraded'},
        {value: 'Car delivered', text: 'Car delivered'},
        {value: 'Cash payment made', text: 'Cash payment made'},
        {value: 'Certificate generated', text: 'Certificate generated'},
        {value: 'Blocking confirmed by phone', text: 'Blocking confirmed by phone'},
        {value: 'Change addr request actioned', text: 'Change addr request actioned'},
        {value: 'Change addr request pending', text: 'Change addr request pending'},
        {value: 'Change addr request received', text: 'Change addr request received'},
        {value: 'Claim declined', text: 'Claim declined'},
        {value: 'Claim Form received', text: 'Claim Form received'},
        {value: 'Claim pending', text: 'Claim pending'},
        {value: 'Complaint pending', text: 'Complaint pending'},
        {value: 'Complaint received', text: 'Complaint received'},
        {value: 'Complaint resolved', text: 'Complaint resolved'},
        {value: 'Confirmation sent', text: 'Confirmation sent'},
        {value: 'Consultant', text: 'Consultant'},
        {value: 'Coop-Partner', text: 'Coop-Partner'},
        {value: 'Coupon received', text: 'Coupon received'},
        {value: 'Customer Update', text: 'Customer Update'},
        {value: 'Declined', text: 'Declined'},
        {value: 'Deposit paid', text: 'Deposit paid'},
        {value: 'Email - Outbound', text: 'Email - Outbound'},
        {value: 'Enquiry made', text: 'Enquiry made'},
        {value: 'Event Calendar', text: 'Event Calendar'},
        {value: 'Exported to file', text: 'Exported to file'},
        {value: 'Fax - Outbound', text: 'Fax - Outbound'},
        {value: 'File received', text: 'File received'},
        {value: 'File sent', text: 'File sent'},
        {value: 'Form completed', text: 'Form completed'},
        {value: 'Form despatched', text: 'Form despatched'},
        {value: 'Form Requested', text: 'Form Requested'},
        {value: 'Hotel booked', text: 'Hotel booked'},
        {value: 'Hotel enquiry', text: 'Hotel enquiry'},
        {value: 'In Process', text: 'In Process'},
        {value: 'In Progress', text: 'In Progress'},
        {value: 'Invalid', text: 'Invalid'},
        {value: 'Key found', text: 'Key found'},
        {value: 'Keys at Affinion', text: 'Keys at Affinion'},
        {value: 'Keys/Luggage despatched', text: 'Keys/Luggage despatched'},
        {value: 'Lost keys received', text: 'Lost keys received'},
        {value: 'Lost keys returned to member', text: 'Lost keys returned to member'},
        {value: 'Magazine', text: 'Magazine'},
        {value: 'Mail - Outbound', text: 'Mail - Outbound'},
        {value: 'Member contacted', text: 'Member contacted'},
        {value: 'Multi User', text: 'Multi User'},
        {value: 'Newsletter', text: 'Newsletter'},
        {value: 'Newspaper Advertisement', text: 'Newspaper Advertisement'},
        {value: 'Not an Issue', text: 'Not an Issue'},
        {value: 'Notified by finder', text: 'Notified by finder'},
        {value: 'Offline', text: 'Offline'},
        {value: 'Online', text: 'Online'},
        {value: 'On Sale', text: 'On Sale'},
        {value: 'Order awaiting payment', text: 'Order awaiting payment'},
        {value: 'Order placed', text: 'Order placed'},
        {value: 'Order shipped', text: 'Order shipped'},
        {value: 'Other', text: 'Other'},
        {value: 'Outbound Call', text: 'Outbound Call'},
        {value: 'Payment auth declined', text: 'Payment auth declined'},
        {value: 'Payment auth received', text: 'Payment auth received'},
        {value: 'Payment auth requested', text: 'Payment auth requested'},
        {value: 'Payment failed', text: 'Payment failed'},
        {value: 'Payment received', text: 'Payment received'},
        {value: 'Payment submitted to BIL', text: 'Payment submitted to BIL'},
        {value: 'Processed', text: 'Processed'},
        {value: 'Promotion bank', text: 'Promotion bank'},
        {value: 'Promotion Email', text: 'Promotion Email'},
        {value: 'Promotion Mail', text: 'Promotion Mail'},
        {value: 'Promotion SMS', text: 'Promotion SMS'},
        {value: 'Radio & TV', text: 'Radio & TV'},
        {value: 'Reassigned', text: 'Reassigned'},
        {value: 'Received', text: 'Received'},
        {value: 'Refund requested', text: 'Refund requested'},
        {value: 'Repair arranged', text: 'Repair arranged'},
        {value: 'Replacement product requested', text: 'Replacement product requested'},
        {value: 'Request with supplier', text: 'Request with supplier'},
        {value: 'Requested', text: 'Requested'},
        {value: 'Reserved', text: 'Reserved'},
        {value: 'Resolution', text: 'Resolution'},
        {value: 'Resolution accepted', text: 'Resolution accepted'},
        {value: 'Resolved', text: 'Resolved'},
        {value: 'Resolved without Cancellation', text: 'Resolved without Cancellation'},
        {value: 'Response from supplier', text: 'Response from supplier'},
        {value: 'Sent', text: 'Sent'},
        {value: 'Sent Online', text: 'Sent Online'},
        {value: 'SMS - Outbound', text: 'SMS - Outbound'},
        {value: 'Stock unavailable', text: 'Stock unavailable'},
        {value: 'Submitted', text: 'Submitted'},
        {value: 'Tickets booked', text: 'Tickets booked'},
        {value: 'Tickets dispatched', text: 'Tickets dispatched'},
        {value: 'Tickets found', text: 'Tickets found'},
        {value: 'Transferred to blocking centre', text: 'Transferred to blocking centre'},
        {value: 'Travel', text: 'Travel'},
        {value: 'Unassigned', text: 'Unassigned'},
        {value: 'Unregistered keys', text: 'Unregistered keys'},
        {value: 'Unreserved', text: 'Unreserved'},
        {value: 'Valid', text: 'Valid'},
        {value: 'Waiting on Customer', text: 'Waiting on Customer'},
        {value: 'Welcome Folder', text: 'Welcome Folder'},
        {value: 'Welcome Pack', text: 'Welcome Pack'},
        {value: 'Welcome Pack Fulfilled', text: 'Welcome Pack Fulfilled'},
      ],
      dataTest: 'substatus',
    },
    {
      fieldType: 'select',
      name: 'channel',
      id: 'channel',
      fieldLabel: 'Channel',
      // defaultValue: 'Mail',
      isDisabled: false,
      xs: 3,
      sm: 3,
      options: [
        {value: 'Authorities', text: 'Authorities'},
        {value: 'Bank Branch', text: 'Bank Branch'},
        {value: 'Call direct to CA', text: 'Call direct to CA'},
        {value: 'Call direct to CR', text: 'Call direct to CR'},
        {value: 'Call to CC', text: 'Call to CC'},
        {value: 'Call transfer to CA', text: 'Call transfer to CA'},
        {value: 'Call transfer to CR', text: 'Call transfer to CR'},
        {value: 'Client - Call', text: 'Client - Call'},
        {
          value: 'Client - Paper / Email',
          text: 'Client - Paper / Email',
        },
        {value: 'Contact Center', text: 'Contact Center'},
        {value: 'Email', text: 'Email'},
        {value: 'Engage', text: 'Engage'},
        {value: 'Fax', text: 'Fax'},
        {value: 'Form', text: 'Form'},
        {value: 'Interface', text: 'Interface'},
        {value: 'IVR', text: 'IVR'},
        {value: 'Letter', text: 'Letter'},
        {value: 'Mail', text: 'Mail'},
        {value: 'MobileCarrier', text: 'MobileCarrier'},
        {value: 'Online', text: 'Online'},
        {value: 'Partner', text: 'Partner'},
        {value: 'Phone', text: 'Phone'},
        {value: 'RBSApp', text: 'RBSApp'},
        {value: 'Unknown', text: 'Unknown'},
      ],
      dataTest: 'channel',
    },
    {
      fieldType: 'select',
      name: 'cancellationReason',
      id: 'cancellationReason',
      fieldLabel: 'Cancellation Reas',
      xs: 3,
      sm: 3,
      options: [
        {value: 'All Order Items Cancelled', text: 'All Order Items Cancelled'},
        {value: 'Cancelled by partner', text: 'Cancelled by partner'},
        {value: 'Changed Mind', text: 'Changed Mind'},
        {value: 'Damaged in Transit', text: 'Damaged in Transit'},
        {value: 'Delivery Period too long', text: 'Delivery Period too long'},
        {value: 'Discontinued', text: 'Discontinued'},
        {value: 'Duplicate Order', text: 'Duplicate Order'},
        {value: 'Faulty Goods', text: 'Faulty Goods'},
        {value: 'Incorrect Goods', text: 'Incorrect Goods'},
        {value: 'Item not delivered', text: 'Item not delivered'},
        {value: 'Late Delivery', text: 'Late Delivery'},
        {value: 'Lost in transit', text: 'Lost in transit'},
        {value: 'Ordered in Error', text: 'Ordered in Error'},
        {value: 'Ordered on wrong membership', text: 'Ordered on wrong membership'},
        {value: 'Out of stock', text: 'Out of stock'},
        {value: 'Out of Stock, Not Replaced', text: 'Out of Stock, Not Replaced'},
        {value: 'Out of Stock, Replaced', text: 'Out of Stock, Replaced'},
        {value: 'Payment Never Received', text: 'Payment Never Received'},
        {value: 'Purchased Elsewhere', text: 'Purchased Elsewhere'},
        {value: 'Show Cancelled', text: 'Show Cancelled'},
        {value: 'Date preference not available', text: 'Date preference not available'},
        {value: 'Directed to website', text: 'Directed to website'},
        {value: 'Member to call back', text: 'Member to call back'},
        {value: 'Other', text: 'Other'},
        {value: 'Sold out', text: 'Sold out'},
        {value: 'Seat preference not available', text: 'Seat preference not available'},
        {value: 'Unable to source', text: 'Unable to source'},
        {value: 'Unhappy with booking fee', text: 'Unhappy with booking fee'},
        {value: 'Unhappy with ticket price', text: 'Unhappy with ticket price'},
        {value: 'Venue preference not available', text: 'Venue preference not available'},
      ],
      dataTest: 'cancellationReason',
    },
    {
      fieldType: 'select',
      name: 'ticketProvider',
      id: 'ticketProvider',
      fieldLabel: 'Ticket Provider',
      xs: 3,
      sm: 3,
      options: [
        {value: 'AAB Ltd', text: 'AAB Ltd'},
        {value: 'Ambassador', text: 'Ambassador'},
        {value: 'Grand Opera House', text: 'Grand Opera House'},
        {value: 'Independent', text: 'Independent'},
        {value: 'RCH', text: 'RCH'},
        {value: 'TICKET MASTER_MANUAL', text: 'TICKET MASTER_MANUAL'},
        {value: 'Ticketsoup', text: 'Ticketsoup'},
        {value: 'Ticketweb', text: 'Ticketweb'},
        {value: 'Waterfront', text: 'Waterfront'},
        {value: 'TICKET MASTER_FILE', text: 'TICKET MASTER_FILE'},
        {value: 'See Tickets', text: 'See Tickets'},
        {value: 'Trafalgar', text: 'Trafalgar'},
      ],
      dataTest: 'subStatus',
    },
    {
      fieldType: 'select',
      name: 'deliveryType',
      id: 'deliveryType',
      fieldLabel: 'Ticket Delivery',
      xs: 3,
      sm: 3,
      options: [
        {value: 'Hard Ticket', text: 'Hard Ticket'},
        {value: 'Ticket Master AI Post Out', text: 'Ticket Master AI Post Out'},
        {value: 'eTicket', text: 'eTicket'},
        {value: 'COBO', text: 'COBO'},
        {value: 'Other AI Post Out', text: 'Other AI Post Out'},
      ],
      dataTest: 'deliveryType',
    },
    {
      fieldType: 'checkbox',
      name: 'isUseMailingAddress',
      id: 'isUseMailingAddress',
      fieldLabel: 'Mailing Address',
      xs: 8,
      sm: 8,
      dataTest: 'isUseMailingAddress',
      required: false,
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'deliveryName',
      id: 'deliveryName',
      fieldLabel: 'Delivery Name',
      display: 'none',
      xs: 3,
      sm: 3,
      dataTest: 'deliveryName',
      hidden: true,
    },

    {
      fieldType: 'text',
      htmlInputType: 'text',
      required: false,
      name: 'addressLineOne',
      id: 'addressLineOne',
      fieldLabel: 'Address Line1',
      display: 'none',
      xs: 3,
      sm: 3,
      dataTest: 'addressLineOne',
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      required: false,
      name: 'addressLineTwo',
      id: 'addressLineTwo',
      fieldLabel: 'Address Line2',
      display: 'none',
      xs: 3,
      sm: 3,
      dataTest: 'addressLineTwo',
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      required: false,
      name: 'addressLineThree',
      id: 'addressLineThree',
      fieldLabel: 'Address Line3',
      display: 'none',
      xs: 3,
      sm: 3,
      dataTest: 'addressLineThree',
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      required: false,
      name: 'city',
      id: 'city',
      fieldLabel: 'City',
      display: 'none',
      xs: 2,
      sm: 2,
      dataTest: 'city',
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      required: false,
      name: 'postalCode',
      id: 'postalCode',
      fieldLabel: 'Postal Code',
      display: 'none',
      xs: 2,
      sm: 2,
      dataTest: 'postalCode',
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      required: false,
      name: 'county',
      id: 'county',
      fieldLabel: 'County',
      display: 'none',
      xs: 2,
      sm: 2,
      dataTest: 'county',
    },
    {
      fieldType: 'select',
      name: 'country',
      id: 'country',
      fieldLabel: 'Country',
      display: 'none',
      xs: 3,
      sm: 3,
      options: [],
      dataTest: 'country',
    },

    {
      fieldType: 'select',
      name: 'state',
      id: 'state',
      fieldLabel: 'State',
      display: 'none',
      xs: 3,
      sm: 3,
      options: [],
      dataTest: 'state',
    },
    {
      fieldType: 'commentBox',
      htmlInputType: 'text',
      rows: 4,
      multiline: true,
      name: 'comments',
      id: 'comments',
      fieldLabel: 'Comments',
      xs: 6,
      sm: 6,
      dataTest: 'comments',
      note: 'Please do not enter any sensitive data',
      readonly: true,
      variant: 'filled',
    },
  ],
};

export const bookEventDetails = {
  sectionClass: '',
  title: 'Event Details',
  titleClass: '',
  fields: [
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'eventName',
      id: 'eventName',
      fieldLabel: 'Event Name',
      xs: 4,
      sm: 4,
      //  required: true,
      dataTest: 'eventName',
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      required: true,
      name: 'venueName',
      id: 'venueName',
      fieldLabel: 'Venue Name',
      xs: 4,
      sm: 4,
      dataTest: 'venueName',
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      required: false,
      name: 'otherVenueName',
      id: 'otherVenueName',
      fieldLabel: 'Other Venue Name',
      xs: 4,
      sm: 4,
      dataTest: 'otherVenueName',
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      required: true,
      name: 'venueCity',
      id: 'venueCity',
      fieldLabel: 'Venue City',
      xs: 4,
      sm: 4,
      dataTest: 'venueCity',
    },
    {
      fieldType: 'dateTimePicker',
      name: 'eventDateTime',
      id: 'eventDateTime',
      fieldLabel: 'Event Date Time',
      required: true,
      xs: 4,
      sm: 4,
      dataTest: 'eventDateTime',
      dateFormat: 'dd/MM/yyyy HH:mm',
      //disablePast: true,
    },
    {
      fieldType: 'select',
      name: 'eventCategory',
      id: 'eventCategory',
      fieldLabel: 'Event Category',
      required: true,
      xs: 4,
      sm: 4,
      options: [
        {value: 'Arts and Theatre', text: 'Arts and Theatre'},
        {value: 'Family and Attractions', text: 'Family and Attractions'},
        {value: 'Music', text: 'Music'},
        {value: 'Other', text: 'Other'},
        {value: 'Sport', text: 'Sport'},
      ],
      dataTest: 'eventCategory',
    },
    {
      fieldType: 'datePicker',
      name: 'bookingDate',
      id: 'bookingDate',
      fieldLabel: 'Purchase Date',
      xs: 4,
      sm: 4,
      dataTest: 'bookingDate',
      isDisabled: true,
    },
    {
      fieldType: 'textarea',
      htmlInputType: 'text',
      name: 'seatDetails',
      id: 'seatDetails',
      fieldLabel: 'Seat Details',
      xs: 6,
      sm: 6,
      rows: 4,
      multiline: true,
      dataTest: 'seatDetails',
    },
    {
      fieldType: 'text',
      htmlInputType: 'text',
      name: 'bookingReference',
      id: 'bookingReference',
      fieldLabel: 'Booking Reference',
      xs: 4,
      sm: 4,
      dataTest: 'bookingReference',
    },
    {
      fieldType: 'text',
      htmlInputType: 'number',
      name: 'postageFee',
      id: 'postageFee',
      fieldLabel: 'Postage €',
      xs: 4,
      sm: 4,
      dataTest: 'postageFee',
    },
    {
      fieldType: 'text',
      htmlInputType: 'number',
      name: 'totalBookingFee',
      id: 'totalBookingFee',
      fieldLabel: 'Total Booking Fee €',
      xs: 4,
      sm: 4,
      dataTest: 'totalBookingFee',
      isDisabled: true,
    },
    {
      fieldType: 'text',
      htmlInputType: 'number',
      name: 'totalActualPrice',
      id: 'totalActualPrice',
      fieldLabel: 'Actual Total Price €',
      xs: 4,
      sm: 4,
      dataTest: 'totalActualPrice',
      isDisabled: true,
    },
    {
      fieldType: 'select',
      htmlInputType: 'select',
      name: 'extraDiscountPercentage',
      id: 'extraDiscountPercentage',
      fieldLabel: 'Extra Discount %',
      xs: 4,
      sm: 4,
      options: [
        {value: 0, text: 0},
        {value: 5, text: 5},
        {value: 10, text: 10},
        {value: 15, text: 15},
        {value: 20, text: 20},
        {value: 25, text: 25},
        {value: 30, text: 30},
        {value: 35, text: 35},
        {value: 40, text: 40},
        {value: 45, text: 45},
        {value: 50, text: 50},
        {value: 55, text: 55},
        {value: 60, text: 60},
        {value: 65, text: 65},
        {value: 70, text: 70},
        {value: 75, text: 75},
      ],
      dataTest: 'extraDiscountPercentage',
    },
    {
      fieldType: 'text',
      htmlInputType: 'number',
      name: 'totalPriceAfterDiscount',
      id: 'totalPriceAfterDiscount',
      fieldLabel: 'Price After Discount €',
      xs: 4,
      sm: 4,
      dataTest: 'totalPriceAfterDiscount',
      isDisabled: true,
    },
  ],
};
export const buttonList = {
  direction: 'row',
  spacing: 2,
  fields: [
    {
      fieldType: 'button',
      htmlInputType: 'submit',
      name: 'cancelButton',
      id: 'cancelButton',
      fieldLabel: 'Cancel',
      variant: 'contained',
      dataTest: 'cancel',
      xs: 2,
      sm: 2,
    },
    {
      fieldType: 'button',
      htmlInputType: 'submit',
      name: 'confirmedButton',
      id: 'confirmedButton',
      fieldLabel: 'Confirmed',
      variant: 'contained',
      dataTest: 'confirmedButton',
      xs: 2,
      sm: 2,
    },
    {
      fieldType: 'button',
      htmlInputType: 'submit',
      name: 'saveButton',
      id: 'saveButton',
      fieldLabel: 'Save',
      variant: 'contained',
      dataTest: 'save',
      xs: 2,
      sm: 2,
    },
  ],
};
